<template>
  <div>
    <v-card color="black" class="mb-2 mb-8 pa-8 py-2" tile dark>
      <v-row id="header" justify="space-between" align="center" no-gutters>
        <v-col class="text-center" sm="12"> {{ title }}</v-col>
      </v-row>
    </v-card>
    <v-card max-height="calc(80vh - 90px)">
      <v-card
        class="pa-8"
        ref="chatWindow"
        max-height="calc(80vh - 172px)"
        style="overflow-y: scroll"
        flat
      >
        <div v-for="n in historys" :key="n.uuid">
          <v-row v-if="n.currentUser === currentUser.user_name" no-gutters>
            <Avatart color="red" v-bind:threeLetters="n.teamName.slice(0, 3)" />
            <ChatCard
              side="left"
              :user="n.currentUser"
              :message="n.comment"
              :date="
                moment(new Date(n.unix_date * 1000)).format('MM/DD  h:mm a')
              "
            />
          </v-row>
          <v-row v-else justify="end" no-gutters>
            <ChatCard
              side="right"
              :user="n.currentUser"
              :message="n.comment"
              :date="
                moment(new Date(n.unix_date * 1000)).format('MM/DD  h:mm a')
              "
            />
            <Avatart
              color="blue"
              :threeLetters="n.teamName.slice(0, 3)"
            />
          </v-row>
        </div>
      </v-card>
      <v-text-field
        @keyup.enter.native="sendMessage"
        @click:append="sendMessage"
        v-model="comment"
        solo
        label="Type Something...."
        append-icon="mdi-send"
        class="pa-0 ma-4"
      />
    </v-card>
  </div>
</template>
<script>
import ChatCard from "../chat/ChatCard";
import Avatart from "../chat/Avatar";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "SmackTalk",
  components: {
    ChatCard,
    Avatart,
  },
  beforeDestroy() {
    this.connection.close();
  },
  data() {
    return {
      title: "SmackTalk",
      connection: null,
      history: null,
      chatChannelName: "avisChatChannel",
      teamName: "",
      teamUuid: "",
      seasonUuid: "",
      comment: "",
    };
  },
  async mounted() {
    await this._history();
    this.setScrollTop();
  },
  computed: {
    ...mapState({
      _chatHistory: "chatHistory",
      _getCurrentUser: "auth/currentUser",
    }),
    ...mapGetters({
      _currentUser: "auth/getCurrentUser",
    }),
    historys: function() {
      return this._chatHistory;
    },
    currentUser() {
      return this._currentUser;
    },
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },
    sendMessage: function() {
      if (this.comment.length < 1) {
        return;
      }
      this._setLoading(true)
      const now = new Date();
      const body = `{
          "action":"onMessage",
          "chatChannelName":"${this.chatChannelName}",
          "comment":"${this.comment}",
          "currentUser": "${this.currentUser?.user_name ? this.currentUser?.user_name : "unknown"}",
          "teamName":"${this.currentUser?.location}",
          "teamUuid": "${this.teamUuid}",
          "seasonUuid":"${this.seasonUuid}",
          "created_at":"${now}"
        }`;
      this.connection.send(body);
      this.comment = "";
      
    },
    ...mapMutations({
      _addChatHistory: "addChatHistory",
       _setLoading: "setLoading",
    }),
    ...mapActions({
      _history: "getChatHistory",
     
    }),
    updateChat: function(event) {
      if (this.historys) {
        const newChat = JSON.parse(event.data);
        this._addChatHistory(newChat);
      }
      this._setLoading(false);
    },
    setScrollTop: function() {
      const container = this.$refs.chatWindow;
      if (container.$el) {
        container.$el.scrollTop = container.$el.scrollHeight;
      }
    },
    startSocket: function() {
      this.connection = null;
      this.connection = new WebSocket(process.env.VUE_APP_WEB_SOCKET_URL);
      this.connection.onmessage = this.updateChat;
      this.connection.onclose = function() {
        // Try to reconnect in 0.4 seconds
        if (this.startSocket) {
          setTimeout(function() {
            this.startSocket(process.env.VUE_APP_WEB_SOCKET_URL);
          }, 400);
        }
      };
      this.connection.onopen = function() {
        console.log("Successfully connected to the echo websocket server...");
      };
    },
  },
  created: function() {
    this.startSocket();
  },
  updated: function() {
    this.setScrollTop();
  },
};
</script>
