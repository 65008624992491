<template>
<v-container fluid fill-height>
  <v-card flat height="auto" width="400px" style="margin: 170px auto 0 auto">
    <v-card flat>

      <div class="datepickder">
        <v-row justify="center">
          <v-date-picker v-model="weekStartDate"></v-date-picker>
        </v-row>
      </div>
      <hr v-if="errorMsg || successMsg" style="margin: 30px auto" />
      <v-row justify="center">

        <h3 style="color: red">{{ errorMsg }}</h3>
        <h3 style="color: green">{{ successMsg }}</h3>
      </v-row>
      <hr style="margin: 30px auto 30px auto" />
      <div class="flex items-center justify-center w-full h-screen text-center">
        <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">

          <v-row justify="center">
            <input style="display:none" type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
          </v-row>
          <v-row justify="center">
            <label for="assetsFieldHandle" class="block cursor-pointer">Pick or Drop File
            </label>
          </v-row>
          <ul class="mt-4" v-if="this.filelist.length" v-cloak style="list-style: none">
            <li class="text-sm p-1" v-for="file in filelist" :key="file.name">
              {{ file.name
                }}<button style="padding: 0 5px; color: rgb(118, 118, 118)" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">
                [remove]
              </button>
            </li>
          </ul>
        </div>
      </div>
      <v-row class="d-flex justify-center">
        <v-checkbox v-model="checkbox" :label="`Final Score: ${checkbox.toString()}`"></v-checkbox>
      </v-row>
      <v-row no-gutters class="pt-5">
        <v-btn @click="uploadWeeklyData" large id="submit" color="primary" style="width: 100%">Upload Weekly Data</v-btn>
      </v-row>
    </v-card>
  </v-card>
</v-container>
</template>

<script>
// import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
import router from "../../router";
// import datepicker from "vuejs-datepicker";

export default {
  data() {
    return {
      filelist: [], // Store our uploaded files
      weekStartDate: this.getLastSunday().toISOString().substr(0, 10),
      errorMsg: "",
      successMsg: "",
      checkbox: false,
    };
  },
  methods: {
    getLastSunday() {
      var t = new Date();
      if (t.getDay() === 0) {
        return t;
      } else {
        t.setDate(t.getDate() - t.getDay());
        return t;
      }
    },
    getCalDate() {
      return this.weekStartDate.replace(/-/g, '/');
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.successMsg = "";
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    yyyymmdd(inputDate) {
      const date = new Date(inputDate);
      const x = date;
      const y = x.getFullYear().toString();
      let m = (x.getMonth() + 1).toString();
      let d = x.getDate().toString();
      d.length == 1 && (d = "0" + d);
      m.length == 1 && (m = "0" + m);
      const yyyymmdd = y + m + d;
      return yyyymmdd;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
      this.successMsg = "";
    },
    checkFileName() {
      if (!this.filelist[0]) {
        this.errorMsg = "Select a file";
        return null;
      }
      const chkName = this.filelist[0].name;

      if (!chkName.match(/-(20[0-9]{6})\./) && !this.getCalDate()) {
        this.errorMsg = "Invalid file name. Please select a date";
        this.successMsg = "";
        return null;
      } else {
        this.errorMsg = "";
        return true;
      }
    },
    async uploadWeeklyData() {
      if (!this.checkFileName()) {
        return null;
      }
      const file = this.filelist[0];
      const newName = this.filelist[0].name.match(/-(20[0-9]{6})\./) ?
        this.filelist[0].name :
        "weekly-data-" + this.yyyymmdd(this.getCalDate()) + "-final-score-" + this.checkbox + ".xlxs";
      var blob = file.slice(0, file.size, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      const uploadFile = new File([blob], newName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      try {
        const path = process.env.VUE_APP_SIGNED_URL_PATH;
        const signedURL = await fetch(path, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: `{"fileName": "${newName}", "contentType" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}`,
        });
        const response = await signedURL.json();
        const fileResponse = await fetch(response.url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "x-ms-blob-type": "BlockBlob",
          },
          body: uploadFile,
        });
        if (fileResponse.status !== 200) {
          this.errorMsg = "Error file did not upload to s3";
          this.successMsg = "";
        } else {
          const res = await fetch(process.env.VUE_APP_WEEKLY_DATA_STATUS_URL + '/Avis/');
          const data = await res.json();
          if (Number(data.count) === Number(64) && 
          (
          this.yyyymmdd(this.getCalDate()) === 
          this.yyyymmdd(data.week_start_date.toString().substr(0, 10).replace(/-/g, '/')))
          ) {
            this.successMsg = "Success";
          } else {
            this.errorMsg = `ERROR: Weely data current as of ${this.yyyymmdd(data.week_start_date.toString().substr(0,10))} total team scores: ${data.count}`;
          }
        }
      } catch (e) {
        this.errorMsg = e;
      }
    },
  },
  watch: {
    weekStartDate: function () {
      this.checkFileName();
    },
    filelist: function () {
      this.checkFileName();
    }
  },
};
</script>

<style></style>
