<template>
  <div>

    <v-card color="black" class="mb-2 mb-8 pa-8 py-2" tile dark>
      <v-row id="header" justify="space-between" align="center" no-gutters>
        <v-col class="text-center" sm="12"> {{ title }}</v-col>
      </v-row>
    </v-card>

    <v-card id="hypeVideo" height="100%">
      <v-img class="ma-0" :height="$vuetify.breakpoint.mdAndUp ? 'calc(80vh - 80px)' : '100%'" :src="require('@/assets/dashbg.png')" >
        
        <div class="center"> 
          <video width="320" height="240" controls>
            <source src="https://d3sjg6vbvao5f1.cloudfront.net/Challenger2022.mp4" type="video/mp4" />
                                  
          
          </video>
        </div>

      
      </v-img>     
    </v-card>
  </div>
</template>

<!-- SCRIPT --> 

<script>



export default {

    data () {
      return {
        //menu title
        title: "Hype Video",   
           
        }
  },
    mounted() {
    this.setError(null);
    
  },
};
</script>

<!-- STYLING ----> 

<style>
video{
  height: 75%; 
  width: 75%;
}

.center {
  margin: auto;
  width: 100%;
  padding: 50px;
  text-align: center;
}
</style>