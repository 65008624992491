<template>

  <playoffs-edit />




</template>

<script>
import PlayoffsEdit from "@/components/app/playoffs-edit.vue";
// import BottomNav from "@/components/app/BottomNav";
export default {
  name: "Playoffs",
  components: {
    PlayoffsEdit,
    // BottomNav,
  },
  mounted() {},
  
};
</script>
