<template>
  <v-card
    color="red darken-2"
    class="ma-0 pa-0 elevation-4"
    style="position: fixed; top: 0px; z-index: 9999; width: 100vw;"
    tile
    dark
  >
    <v-row align="center" no-gutters>
      <v-card-title>
        <img :src="abgIcon" />
      </v-card-title>
      <v-spacer />
      <!-- <v-btn-toggle v-model="navigation" group mandatory tile>
        <v-btn class="mr-6" large>
          home
        </v-btn>
        <v-btn class="mr-6" large>
          Groups
        </v-btn>
        <v-btn class="mr-6" large>
          screens
        </v-btn>
        <v-btn class="mr-6" large>
          reconciliation
        </v-btn>
        <v-btn class="mr-6" large>
          reports
        </v-btn>
        <v-btn class="mr-6" large>
          device locations
        </v-btn>
        <v-btn class="mr-6" large>
          challenger
        </v-btn>
      </v-btn-toggle> -->
      <v-spacer></v-spacer>
      <AccountCard />
    </v-row>
    <Breadcrumbs />
  </v-card>
</template>
<script>
import AccountCard from "./AccountCard";
import Breadcrumbs from "./Breadcrumbs.vue";
export default {
  components: { AccountCard, Breadcrumbs },
  data() {
    return {
      navigation: 6,
      tab: null,
      abgIcon: require("../../assets/abg-logo.svg"),
      items: ["web", "shopping", "videos", "images", "news"],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

Breadcrumbs
