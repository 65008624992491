<template>
	<v-card tile color="white" >
    <v-card-subtitle class="black--text" >
    <span @click="navigateHome()" style="cursor: pointer;">Challenger</span>
    <v-icon light>mdi-chevron-right</v-icon>
    {{page}}

    </v-card-subtitle>
	</v-card>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
data(){
  return {}
},
computed: {
  ...mapState({
    page: "page"
  })
}, methods: {
    ...mapMutations({
      _changePage: "changePage",
    }),
    navigateHome() {
      if (this.$router.name !== "Home") {
        this.$router.push("/");
      }
    },
  },
};
</script>
