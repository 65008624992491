<template>
  <v-card
    class="elevation-0 pt-16 mt-16"
    :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : ''"
    color="grey lighten-3"
    style="width: 100vw;"
    :height="$vuetify.breakpoint.mdAndUp ? '100vh' : ''"
    flat
  >
    <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters>
      <Navigation />
      <MainPage />
    </v-row>
    <v-row
      justify="center"
      align="center"
      width="100vw"
      class="mb-16"
      no-gutters
      id="mobileScreen"
      v-else
    >
      <MainPage />
    </v-row>
    <BottomNav v-if="$vuetify.breakpoint.smAndDown" />
    <v-overlay :value="_loading">
      <v-progress-circular
        color="red"
        indeterminate
        size="84"
      ></v-progress-circular>
      <v-card-subtitle class="white--text">Loading...</v-card-subtitle>
    </v-overlay>
  </v-card>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/app/Navigation";
import MainPage from "@/components/app/MainPage";
import { mapActions, mapState } from "vuex";
import BottomNav from "@/components/app/BottomNav";

export default {
  name: "Home",
  components: {
    Navigation,
    MainPage,
    BottomNav,
  },
  computed: {
    ...mapState({
      _loading: "loading",
    }),
  },
  methods: {
    ...mapActions({
      // _getAllSeasons: "getAllSeasons",
    })
  }
  
};
</script>
