export async function query(body) {
  let res = await fetch(
    process.env.VUE_APP_GRAPHQL_ENDPOINT,
    {
      body,
      method: "POST",
      "content-length": body.length,
    }
  );
  var json = await res.json();
  return json.data;
}
export async function fetchChatHistory(limit) {
  const chatLimit = limit || 50;
  let res = await fetch(
    process.env.VUE_APP_CHAT_HISTORY_ENDPOINT + '/' + chatLimit
  );
  var json = await res.json();
  return json;
}
export async function fetchMvpText(limit) {
  const id = limit || 1;
  console.log('fetch text')
  let res = await fetch(
    process.env.VUE_APP_MVP_TEXT_EDIT_URL + '/' + id
  );
  var json = await res.json();
  return (json.Items && json.Items[0] && json.Items[0].textJSON);
}
export async function auth(path) {
  var base = process.env.VUE_APP_LOGIN_GET_ALL;
  // var base = 'https://8rsj323ns6.execute-api.us-east-1.amazonaws.com/dev/api'
  let res = await fetch(base + path);
  // var json = await res.json();
  return res.json();
}