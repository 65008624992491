<template>
  <v-card
    :class="$vuetify.breakpoint.mdAndUp ? 'ma-6 pa-0' : 'ma-2'"
    color="transparent"
    :width="
      $vuetify.breakpoint.xlAndUp
        ? 'calc(70vw - 80px)'
        : $vuetify.breakpoint.mdAndUp
        ? 'calc(80vw - 100px)'
        : 'calc(90vw)'
    "
    :max-height="$vuetify.breakpoint.mdAndUp ? '80vh' : ''"
	id="mainPage"
    style="overflow-y: auto;"
    flat
    tile
  >
    <component :is="page"></component>
  </v-card>
</template>
<script>
import Dashboard from "@/components/pages/Dashboard";
import Schedule from "@/components/pages/Schedule";
import SmackTalk from "@/components/pages/SmackTalk";
import Standings from "@/components/pages/Standings";
import Login from "@/components/login/LoginMain";
import HypeVideo from "@/components/pages/HypeVideo";
import { mapState } from "vuex";
export default {
  components: {
    Dashboard,
    Schedule,
    SmackTalk,
    Standings,
    Login,
    HypeVideo, 
  
  },
  data() {
    return {
      navi: ["Dashboard",  "Schedule", "SmackTalk", "HypeVideo"],
      showMenu: true,
      upperMenu: ["Log out"],
    };
  },
  computed: {
    ...mapState({
      page: "page",
    }),
  },
};
</script>