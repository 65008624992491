import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import WeeklyUpload from "../views/WeeklyUpload.vue";
import PlayoffsEdit from "../views/PlayoffsEdit.vue";
import store from "../_store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
    component: Home,
  },
  {
    path: "/upload",
    name: "Upload",
    meta: {
      requiresAuth: true,
    },
    component: WeeklyUpload,
  },
  {
    path: "/editmvp",
    name: "PlayoffsEdit",
    meta: {
      requiresAuth: true,
    },
    component: PlayoffsEdit,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/verify",
    name: "Verify",
    component: () =>
      import(/* webpackChunkName: "login" */ "../components/login/Verify.vue"),
  },
  // {
  //   path: "/invite",
  //   name: "Invite",
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ "../components/pages/Invite.vue"),
  // },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../components/login/Register.vue"
      ),
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/ForgotPassword.vue"
      ),
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../components/login/ChangePassword.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.loggedIn) {
      store.state.displayLogOut = true;
      next();
    } else if (localStorage.getItem("auth")) {
      try {
        let user = JSON.parse(atob(localStorage.getItem("auth")));
        user.password = new Buffer.from(user.password, "base64").toString(
          "ascii"
        );
        // store.dispatch("auth/logIn", user, { root: true });
        store.state.page = "Dashboard";
        store.state.displayLogOut = true;
        next();
      } catch (e) {
        console.error(e);
        next("/login");
      }
    } else {
      store.state.displayLogOut = false;
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
