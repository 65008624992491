<template>
<v-card-title v-if="showLogOut" style="background: rgba(0, 0, 0, 0.2)" class="ma-0 pa-4 elevation-0" tile>
  <v-menu  offset-y bottom :close-on-click="closeOnClick">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="white" icon v-bind="attrs" v-on="on">
        <v-icon color="black">mdi-account-circle</v-icon>
      </v-btn>
    </template>
    <v-list >
      <v-list-item @click="upperMenuOptions(item)" v-for="(item, index) in upperMenu" :key="index">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</v-card-title>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
} from "vuex";
export default {
  data() {
    return {
      upperMenu: ["Log out"],
      closeOnClick: true,
    };
  },
  computed: {
    ...mapGetters({
      showLogOut: "displayLogOut"
    }),
  },
  methods: {
    ...mapMutations({
      _changePage: "changePage",
      _setDisplayLogOut: "setDisplayLogOut"
    }),
    ...mapActions({
      _logOut: "auth/logOut",
    }),

    upperMenuOptions(item) {
      if (item === "Log out") {
        this._logOut();
        this._setDisplayLogOut(false);
        this._changePage("Login");
      }
      return null;
    },
  },
};
</script>
