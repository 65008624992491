<template>
  <weekly-upload />
</template>

<script>
import WeeklyUpload from "@/components/app/weekly-upload.vue";

export default {
  components: {
    WeeklyUpload,
  },
  mounted() {}
};
</script>
