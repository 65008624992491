<template>
  <div>
    <v-card color="black" class="mb-2 mb-8 px-8 py-2" tile dark>
      <v-row id="header" justify="space-between" align="center" no-gutters>
        <v-col class="text-center" sm="12"> {{ title }}</v-col>
      </v-row>
    </v-card>
    <v-card class="pa-8" min-height="100%">
      <v-img
        class="ma-0"
        :src="require('@/assets/Challenger1.png')"
        style="position: absolute; top:0; right: 0; left: 0;"
      />
      <v-row no-gutters>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-col
          sm="12"
          md="5"
          :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-3'"
        >
          <filter-tree />
        </v-col>
      </v-row>
      <SortBar />
      <!-- <v-card class="ma-4 elevation-0" color="grey" dark>
        <v-row justify="space-between" no-gutters>
          <v-col sm="6">
            <v-card-subtitle class="my-0 py-1 text-button">
              {{ league }}
            </v-card-subtitle>
          </v-col>
          <v-col style="text-align: right" sm="6">
            <v-card-subtitle class="my-0 py-1 text-button">
              {{ region }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card> -->
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-virtual-scroll
          :items="standings"
          height="400"
          item-height="116"
          class="pb-4"
        >
          <template v-slot:default="{ item }">
            <v-card class="pa-6 mt-4 mx-4 elevation-5" max-height="100px" light>
              <v-row align="center" no-gutters>
                <v-col :sm="headers[0].size">
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      <!-- TODO: replace with team avatar -->
                      <v-avatar color="red" class="white--text" size="46">
                        <!-- <v-icon>mdi-account</v-icon> -->
                        {{ item.teamName.slice(0, 3) }}
                      </v-avatar>
                    </v-col>
                    <v-col sm="8">
                      <span>
                        {{ item.teamName }}
                      </span>
                      <br />
                      <span>
                        {{ item.teamLocation }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :sm="headers[1].size">
                  <span id="record" v-if="!isNaN(item.totalWins)">
                     {{ Number(item.totalWins) }} -
                    {{ Number(item.totalGames) - Number(item.totalWins) }}
                  </span>
                  <span id="record" v-else>
                    -
                    </span>
                </v-col>
                <v-col :sm="headers[2].size">
                  <span id="avgPen">
                    {{ formatPercent(item.avgPenetration) }}
                  </span>
                </v-col>
                <v-col :sm="headers[3].size">
                  <span id="winPerc">
                    {{ (Number(item.winPercentage)*100).toFixed(0) || 0 }}%
                  </span>
                </v-col>
                <v-col :sm="headers[4].size">
                  <span id="streak" v-if="item.winStreak">
                    {{ item.winStreak }}
                    {{ item.winStreak > 1 ? "games" : "game &nbsp;" }} - [W]
                  </span>
                  <span id="streak" v-else-if="item.loseStreak">
                    {{ item.loseStreak }}
                    {{ item.loseStreak > 1 ? "games" : "game &nbsp;" }} - [L]
                  </span>
                  <span id="streak" v-else>
                  -
                  </span>
                </v-col>
                <v-col :sm="headers[5].size">
                  <span id="totalRev">
                    <money-format
                      :value="item.totalRevenue || 0"
                      locale="en"
                      currency-code="USD"
                      :subunits-value="false"
                      :hide-subunits="true"
                    >
                    </money-format
                  ></span>
                </v-col>
                <!-- <v-col sm="1">
              <v-icon>mdi-pencil</v-icon>
            </v-col> -->
              </v-row>
            </v-card>
          </template>
        </v-virtual-scroll>
      </div>
      <v-data-iterator v-else :items="standings" item-key="teamName">
        <template v-slot:default="props">
          <v-card class="mt-4" v-for="(i, k) in props.items" :key="k">
            <v-list-item>
              <v-list-item-content> Team Name: </v-list-item-content>
              <v-list-item-content>
                {{ i.teamName }}
              </v-list-item-content></v-list-item
            >
            <v-list-item>
              <v-list-item-content>
                Record
              </v-list-item-content>
              <v-list-item-content>
                <span id="record" v-if="!isNaN(i.totalWins)">
                  {{ i.totalWins }} -
                  {{ Number(i.totalGames) - Number(i.totalWins) }}
                </span>
                <span id="record" v-else>
                  -
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content> AVG P/R:</v-list-item-content>
              <v-list-item-content>
                {{ formatPercent(i.avgPenetration) }}</v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                Streak:
              </v-list-item-content>
              <v-list-item-content>
                <span id="streak" v-if="i.winStreak">
                  {{ i.winStreak }}
                  {{ i.winStreak > 1 ? "games" : "game &nbsp;" }} - [W]
                </span>
                <span id="streak" v-else-if="i.loseStreak">
                  {{ i.loseStreak }}
                  {{ i.loseStreak > 1 ? "games" : "game &nbsp;" }} - [L]
                </span>
                <span id="streak" v-else>
                  -
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                Win%:
              </v-list-item-content>
              <v-list-item-content>
                <span id="winPerc">
                  {{ (Number(i.winPercentage)*100).toFixed(0) || 0 }}%
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template></v-data-iterator
      >
 
    </v-card>
    
  </div>
  
</template>
<script>
import SortBar from "../app/SortBar";
import { mapActions, mapState } from "vuex";
import MoneyFormat from "vue-money-format";
import FilterTree from "@/components/app/FilterTree.vue";
export default {
  components: {
    "money-format": MoneyFormat,
    FilterTree,
    SortBar,
  },
  data() {
    return {
      title: "Standings",
      headers: [
        { size: 3, name: "TEAM" },
        { size: 1, name: "RECORD", sortBy: "totalWins" },
        { size: 2, name: "AVG PENETRATION", sortBy: "avgPenetration" },
        { size: 2, name: "WIN %", sortBy: "winPercentage" },
        { size: 2, name: "STREAK" },
        { size: 2, name: "TOTAL REVENUE", sortBy: "totalRevenue" },
        // { size: 1, name: "EDIT" },
      ],
      filterVal: undefined,
      record: "6-3",
      sortBy: undefined,
      league: "American Football Conference",
      region: "AFC NORTH",
      teamName: "The Armodillos",
      teamCity: "Las Vegas",
    };
  },
  methods: {
    ...mapActions({
      getStandings: "getStandings",
    }),
    sort(i) {
      this.sortBy = i;
    },
    formatPercent(val) {
      if (val) {
        return val.toFixed(2) + "%"
      } else {
        return "0%";
      }
    }
  },
  mounted() {
    this.getStandings();
  },
  computed: {
    ...mapState({
      _standings: "standings",
      _leagueTeams: "leagueTeams",
      _conferenceName: "conferenceName",
      _divisionName: "divisionName",
      _teamName: "teamName"
    }),
    standings() {
      const listItems = this._standings || this._leagueTeams || [];
      const immuteListItems = [...listItems];
      if (this._teamName) {
        return immuteListItems.filter((item) => {
          return item.teamName === this._teamName;
        })
      }
      if (this._conferenceName && this._divisionName) {
        return immuteListItems.filter((item) => {
          return (item.conferenceName === this._conferenceName && item.divisionName === this._divisionName)
        })
      }
      if (this._conferenceName ) {
        return immuteListItems.filter((item) => {
          return item.conferenceName === this._conferenceName;
        })
      }
      if (this._divisionName) {
        return immuteListItems.filter((item) => {
          return item.divisionName === this._divisionName;
        })
      }
      return listItems;
    },
  },
};
</script>
