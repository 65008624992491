<template functional>
	<v-card
		min-width="calc(40%)"
		max-width="calc(60%)"
		class="mb-2 mt-2"
		:style="
			props.side === 'left'
				? 'border-radius: 0 20px 20px 20px;'
				: 'border-radius: 20px 0 20px 20px;'
		"
		:dark="props.side === 'right'"
	>
		<v-card-subtitle>
			{{ props.user }}
		</v-card-subtitle>
		<v-card-text>
			<div class="body-1">{{ props.message }}</div>
			<div class="body-2">{{ props.date }}</div>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	functional: true,
	props: {
		side: String,
		user: String,
		message: String,
		date: String,
	},
};
</script>
