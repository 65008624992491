<template>
  <v-app>
    <Tbar />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Tbar from "./components/app/Toolbar";
import { mapActions } from "vuex";
import "@/assets/global.css";
export default {
  name: "App",

  components: {
    Tbar,
  },
  mounted() {
    this._checkToken();
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions({
      _checkToken: "auth/checkToken",
    }),
  },
};
</script>
