import Vue from "vue";
import Vuex from "vuex";
import { query } from "../api";
import { fetchChatHistory } from "../api";
import { fetchMvpText } from "../api";

import auth from "./modules/auth";
var gql = require("nanographql");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    page: "Login",
    team: undefined,
    user: undefined,
    schedule: undefined,
    standings: undefined,
    loading: false,
    sortBy: "totalWins",
    seasonUUID: "d88ab534-4bb9-43a2-af03-dc8793220bcf",
    divisionName: "",
    conferenceName: "",
    teamId: "b27f7a75-b28b-4524-a130-915387be6676",
    sortOrder: "desc",
    chatHistory: undefined,
    mvpText: "",
    teamName: "",
    leagueTeams: "",
    displayLogOut: false,
    mappedStandings: {},
  },
  actions: {
    getCurrentSeason: async ({ commit, state }) => {
      commit("setLoading", true);
      const companyName = state.currentClient || "Avis";
      let queryString = `
      {
        getCurrentSeason(companyName: "${companyName}") {
          startDate
          seasonName
          seasonUuid
        }
      }
      `;
      var schema = gql(queryString);
      try {
        const body = schema({ name: "get current season" });
        let json = await query(body);
        commit("setCurrentSeason", json.getCurrentSeason[0]);
        commit("setLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
    getAllSeasons: async ({ commit, state }) => {
      commit("setLoading", true);
      const companyName = state.currentClient || "Avis";
      let queryString = `
      {
        getAllSeasons(companyName: "${companyName}") {
          startDate
          seasonName
          seasonUuid
        }
      }
      `;
      var schema = gql(queryString);
      try {
        const body = schema({ name: "get current season" });
        let json = await query(body);
        const currentSeason = json.getAllSeasons.filter((i) => {
          return i.seasonName === state.currentClient;
        });
        commit("setCurrentSeason", currentSeason[0]);
        commit("setLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
    getLeagueTeams: async ({ commit, state }) => {
      commit("setLoading", true);
      const companyName = state.currentClient || "Avis";
      let queryString = `
      {
        getLeagueTeams(companyName:"${companyName}") {
          teamName
          teamUuid
          teamLocation
          divisionName
          conferenceName
        }
      }
      `;
      var schema = gql(queryString);
      try {
        const body = schema({ name: "get league teams" });
        let json = await query(body);
        commit("setLeagueTeams", json.getLeagueTeams);
        commit("setLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
    getDashboard: async ({ commit, state, rootState }) => {
      commit("setLoading", true);
      const location = rootState.auth.currentUser.location || "";
      const groupedTeamList = {
        E43: "NYZ1",
        E11: "NYZ1",
        YUL: "CANADA",
        YOW: "CANADA",
        YYZ: "CANADA",
        YVR: "CANADA",
        S50: "CANADA",
        YYC: "CANADA",
        DC2: "DCZ",
        MW5: "DCZ",
        X4C: "DCZ",
        W54: "NYZ2",
        XB4: "NYZ2",
        U9A: "NYZ3",
        E90: "NYZ3"
      };
      const searchLocation = groupedTeamList[location] || location;
      const myTeam = state.leagueTeams.filter((i) => {
        return i.teamName.toLowerCase() === searchLocation.toLowerCase();
      });
      const teamUuid = myTeam[0] ? myTeam[0].teamUuid : [];
      commit("setTeam", myTeam[0] ? myTeam[0] : []);
      let queryString = `
        {
          getTeamStandings(
            seasonUUID: "${state.currentSeason.seasonUuid}"
            teamUUID: "${teamUuid}"
          ) {
            teamName
            teamUuid
            teamLogo
            teamLocation
            divisionName
            conferenceName
            totalWins
            totalGames
            totalRevenue
            avgPenetration
            winStreak
            loseStreak
            winPercentage
          }
        }
      `;
      var schema = gql(queryString);
      try {
        const body = schema({ name: "get team" });
        let json = await query(body);
        if (json.getTeamStandings !== null) {
          commit("setTeam", await json.getTeamStandings);
        }
        commit("setLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
    initStandings: async ({ commit, state }) => {
      commit("setLoading", true);
      var queryString = `
        {
          getStandings(filter: 
            {
              seasonUUID: "${state.currentSeason.seasonUuid}"
            }) {
            teamName 
            teamUuid
            teamLogo
            teamLocation
            divisionName
            conferenceName
            totalWins
          totalGames
          totalRevenue
          avgPenetration
          winStreak
          loseStreak
            winPercentage
          }
        }
      `;
      var schema = gql(queryString);
      try {
        const body = schema({ name: "get standings" });
        let json = await query(body);
        commit("setStandings", json.getStandings);
        commit("setLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
    getStandings: async ({ commit, state }) => {
      commit("setLoading", true);
      var queryString = `
        {
          getStandings(filter: 
            {
              seasonUUID: "${state.currentSeason.seasonUuid}"
              divisionName: "${state.divisionName}"
              conferenceName: "${state.conferenceName}",
              teamName: "${state.teamName}"
              orderBy: ${state.sortBy}
              order: ${state.sortOrder}
            }) {
            teamName 
            teamUuid
            teamLogo
            teamLocation
            divisionName
            conferenceName
            totalWins
          totalGames
          totalRevenue
          avgPenetration
          winStreak
          loseStreak
            winPercentage
          }
        }
      `;
      var schema = gql(queryString);
      try {
        const body = schema({ name: "get standings" });
        let json = await query(body);
        commit("setStandings", json.getStandings);
        commit("mapStandings", json.getStandings)
        commit("setLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
    getSchedule: async ({ commit, state }) => {
      commit("setLoading", true);
      let queryString = `{
        getScheduleView(filter: {
      seasonUUID: "${state.currentSeason.seasonUuid}"
      divisionName: "${state.divisionName}"
      teamName: "${state.teamName}"
    }) {
        weekDate
        weekNumber
        games {
        companyName
        seasonName
        weekStartDate
        weekNumber
        homeTeam {
          teamName
          teamConference
          teamDivision
          teamTotalRevenue
          teamAvgPenetration
        }
        awayTeam {
          teamName
          teamConference
          teamDivision
          teamTotalRevenue
          teamAvgPenetration
        }
        }
      }
      }
      `;
      var schema = gql(queryString);
      try {
        const body = schema({ name: "get schedule" });
        let json = await query(body);
        commit("setSchedule", json.getScheduleView);
        commit("setLoading", false);
        commit("setDnC", json.getScheduleView);
      } catch (err) {
        console.error(err);
      }
    },
    getMvpText: async ({ commit }) => {
      commit("setLoading", true);
     const text = await fetchMvpText();
      commit("setMvpText", text);
      commit("setLoading", false);
    },
    changePage: ({ commit }, payload) => {
      commit("changePage", payload);
    },

    getChatHistory: async ({ commit }) => {
      commit("setLoading", true);
      try {
        let json = await fetchChatHistory();
        commit("setChatHistory", json);
        commit("setLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
  },
  getters: {
    getCurrentSeason: (state) => {
      return state.currentSeason;
    },
    displayLogOut: (state) => {
      return state.displayLogOut;
    },
  },
  mutations: {
    setLoading: (state, filter) => {
      state.loading = filter;
    },
    setSort: (state, sort) => {
      if (sort !== null) {
        state.sortBy = sort;
      } else {
        state.sortBy = "totalWins";
      }
    },
    setDivision: (state, division) => {
      if (division !== null) {
        state.divisionName = division;
      } else {
        state.divisionName = "";
      }
    },
    setTeamName: (state, team) => {
      if (team !== null) {
        state.teamName = team;
      } else {
        state.teamName = "";
      }
    },
    setConference: (state, conf) => {
      if (conf !== null) {
        state.conferenceName = conf;
      } else {
        state.conferenceName = "";
      }
    },
    setDnC: (state, data) => {
      const initObj = {
        conferenceList: new Set(),
        divisionList: new Set(),
        teamList: new Set(),
      };
      const dncReducer = (_, week) => {
        return week.games.reduce(
          ({ conferenceList, divisionList, teamList }, c) => {
            conferenceList.add(c.homeTeam.teamConference);
            divisionList.add(c.homeTeam.teamDivision);
            teamList.add(c.homeTeam.teamName);

            conferenceList.add(c.awayTeam.teamConference);
            divisionList.add(c.awayTeam.teamDivision);
            teamList.add(c.awayTeam.teamName);

            return { conferenceList, divisionList, teamList };
          },
          initObj
        );
      };
      const toObj = (arr) => {
        return arr.reduce((a, c) => {
          a.push({ id: c, label: c });
          return a;
        }, []);
      };
      const sortedArray = (arr) => {
        function compare(a, b) {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        }
        return arr.sort(compare);
      };
      const dncData = data.reduce(dncReducer, initObj);
      state.conferenceList = toObj([...dncData.conferenceList]);
      state.divisionList = toObj([...dncData.divisionList]);
      state.teamList = sortedArray(toObj([...dncData.teamList]));
    },
    mapStandings: (state, standings) => {
      state.mappedStandings = standings.reduce((a,c) => {
        if (!a[c.teamName]) {
          a[c.teamName] = {};
        }
        a[c.teamName] = c;
        return a;
      }, {});
    },
    setDisplayLogOut: (state, display) => {
      state.displayLogOut = display;
    },
    changePage: (state, page) => {
      state.page = page;
    },
    setCurrentSeason: (state, currentSeason) => {
      state.currentSeason = currentSeason;
    },
    setLeagueTeams: (state, leagueTeams) => {
      state.leagueTeams = leagueTeams;
    },
    setTeam: (state, team) => {
      state.team = team;
    },
    setStandings: (state, standings) => {
      state.standings = standings;
    },
    setSchedule: (state, schedule) => {
      state.schedule = schedule;
    },
    setChatHistory: (state, chatHistory) => {
      state.chatHistory = chatHistory;
    },
    setMvpText: (state, mvpText) => {
      state.mvpText = mvpText;
    },
    addChatHistory: (state, newChat) => {
      state.chatHistory.push(newChat);
    },
  },
  modules: {
    auth,
  },
});
