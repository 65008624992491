<template>
  <v-container fluid fill-height>
    <v-card flat height="auto" width="300px" style="margin: 70px auto 0 auto">
      <v-card flat>
        <v-form v-model="isFormVaild">
          <v-row no-gutters justify="center">
            <v-text-field
              filled
              hide-details
              id="username"
              prepend-inner-icon="mdi-email"
              label="your@example.com"
              :rules="emailRules"
              v-model="email"
              style="margin: 10px; margin-top: 0 !important; padding: 0px"
            />
          </v-row>
          <v-row no-gutters justify="center">
            <v-text-field
              v-on:keyup.enter="logIn"
              filled
              hide-details
              id="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-inner-icon="mdi-lock"
              label="Your Password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              style="margin: 10px; padding: 0px"
            />
          </v-row>
        </v-form>
        <v-row class="pt-3 d-flex justify-center">
          <h4 style="color: red">{{ getError }}</h4>
        </v-row>
        <v-row no-gutters class="pt-5">
          <v-btn
            @click="logIn"
            large
            id="submit"
            color="primary"
            style="width: 100%"
            :disabled="!isFormVaild"
            >LOG IN</v-btn
          >
        </v-row>
        <v-row no-gutters>
          <v-card-subtitle style="margin: 0 auto">
            <v-btn text @click="openForgot" id="forgot-password"
              >Forgot Password?</v-btn
            >
          </v-card-subtitle>
        </v-row>
        <v-row no-gutters>
          <div style="margin: 0 auto">
            New user?
            <a href="https://challenger.edisoninteractive.com/register">Click here to register!</a>
          </div>
        </v-row>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
import router from "../../router";
export default {
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      showLoading: false,
      isFormVaild: false,
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
    };
  },
  methods: {
    ...mapActions({
      _logIn: "auth/logIn",
    }),
    ...mapMutations({
      setError: "auth/setError",
      setSuccess: "auth/setSuccess",
    }),
    logIn() {
      this.setError(null);
      this.showLoading = true;
      this._logIn(this.user);
    },
    openForgot() {
      this.setError(null);
      router.push("/forgotpassword");
    },
  },
  mounted() {
    this.setError(null);
  },
  computed: {
    ...mapState({
      _currentSeason: "currentSeason",
    }),
    ...mapGetters({
      getError: "auth/getError",
      getSuccess: "auth/getSuccess",
      loggedIn: "auth/getIsLoggedIn",
    }),
    user() {
      return {
        email: this.email,
        password: this.password,
        attributes: {},
      };
    },
  },
  watch: {},
};
</script>

<style></style>
