<template>
    <v-container fluid fill-height>
        <v-card flat height="auto" width="100%" style="margin: 170px auto 0 auto">
    
      
    
            <div id="bracket" contenteditable="true">
<main id="tournament">
  <ul class="round round-1">
    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Lousville <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">NC A&T <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Colo St <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Missouri <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top ">Oklahoma St <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom winner">Oregon <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Saint Louis <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">New Mexico St <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Memphis <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">St Mary's <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Mich St <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Valparaiso <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Creighton <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Cincinnati <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Duke <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Albany <span>-</span></li>

   <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Lousville <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">NC A&T <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Colo St <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Missouri <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top ">Oklahoma St <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom winner">Oregon <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Saint Louis <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">New Mexico St <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Memphis <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">St Mary's <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Mich St <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Valparaiso <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Creighton <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Cincinnati <span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">Duke <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">Albany <span>-</span></li>

    <li class="spacer">&nbsp;</li>
  </ul>
  <ul class="round round-1">
    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">- <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top ">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom winner">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
  </ul>
  <ul class="round round-2">
    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top ">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom winner">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top ">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom winner">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
  </ul>
  <ul class="round round-3">
    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top ">- <span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom winner">-<span>-</span></li>

    <li class="spacer">&nbsp;</li>
  </ul>
  <ul class="round round-4">
    <li class="spacer">&nbsp;</li>
    
    <li class="game game-top winner">-<span>-</span></li>
    <li class="game game-spacer">&nbsp;</li>
    <li class="game game-bottom ">-<span>-</span></li>
    
    <li class="spacer">&nbsp;</li>
  </ul>   
</main>
     </div>
                <v-row no-gutters class="pt-5">
                    <v-btn @click="saveMvpText" large id="submit" color="primary" style="width: 100%">Save</v-btn>
                </v-row>
    
        </v-card>      
      
    </v-container>
</template>

<script>
import {
    mapActions,
    mapState,
    mapMutations
} from "vuex";
export default {
    data() {
        return {
          statusMsg: "",
        };
    },
    methods: {
        async saveMvpText() {
          
            const textJSON = {
                firstPlace: document.getElementById("bracket").innerHTML || "",
                firstPlaceLine2: this.firstPlaceLine2 || "",
                firstPlacePenRate: this.firstPlacePenRate || "",
                secondPlace: this.secondPlace || "",
                secondPlaceLine2: this.secondPlaceLine2 || "",
                secondPlacePenRate: this.secondPlacePenRate || "",
                thirdPlace: this.thirdPlace || "",
                thirdPlaceLine2: this.thirdPlaceLine2 || "",
                thirdPlacePenRate: this.thirdPlacePenRate || "",
                notes1: this.notes1 || "",
                notes2: this.notes2 || "",
                notes3: this.notes3 || "",
                notes4: this.notes4 || "",
                notes5: this.notes5 || "",
            }
            try {
                const res = await fetch(process.env.VUE_APP_MVP_TEXT_EDIT_URL, {
                    method: 'PUT',
                    body: JSON.stringify({ textId: "1", textJSON })
                });
                this.statusMsg = res;
            } catch (e) {
              console.error(e)
              this.statusMsg = e;
            }

        },
        ...mapActions({
            _getMvpText: "getMvpText",
        }),
        ...mapMutations({
            _setLoading: "setLoading",
        }),
    },
    async mounted() {
        await this._getMvpText();
    this.$nextTick(function() {
      document.getElementById("bracket").innerHTML = this._mvpText.firstPlace;
    });
    },
    computed: {
        ...mapState({
            _mvpText: "mvpText",
        }),
        getMvpText: function() {
            return this._mvpText;
        },
        firstPlace: {
            get() {
                return this.getMvpText.firstPlace;
            },
            set(value) {
                this.getMvpText.firstPlace = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        firstPlaceLine2: {
            get() {
                return this.getMvpText.firstPlaceLine2;
            },
            set(value) {
                this.getMvpText.firstPlaceLine2 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        firstPlacePenRate: {
            get() {
                return this.getMvpText.firstPlacePenRate;
            },
            set(value) {
                this.getMvpText.firstPlacePenRate = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        secondPlace: {
            get() {
                return this.getMvpText.secondPlace;
            },
            set(value) {
                this.getMvpText.secondPlace = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        secondPlaceLine2: {
            get() {
                return this.getMvpText.secondPlaceLine2;
            },
            set(value) {
                this.getMvpText.secondPlaceLine2 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        secondPlacePenRate: {
            get() {
                return this.getMvpText.secondPlacePenRate;
            },
            set(value) {
                this.getMvpText.secondPlacePenRate = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        thirdPlace: {
            get() {
                return this.getMvpText.thirdPlace;
            },
            set(value) {
                this.getMvpText.thirdPlace = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        thirdPlaceLine2: {
            get() {
                return this.getMvpText.thirdPlaceLine2;
            },
            set(value) {
                this.getMvpText.thirdPlaceLine2 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        thirdPlacePenRate: {
            get() {
                return this.getMvpText.thirdPlacePenRate;
            },
            set(value) {
                this.getMvpText.thirdPlacePenRate = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        notes1: {
            get() {
                return this.getMvpText.notes1;
            },
            set(value) {
                this.getMvpText.notes1 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        notes2: {
            get() {
                return this.getMvpText.notes2;
            },
            set(value) {
                this.getMvpText.notes2 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        notes3: {
            get() {
                return this.getMvpText.notes3;
            },
            set(value) {
                this.getMvpText.notes3 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        notes4: {
            get() {
                return this.getMvpText.notes4;
            },
            set(value) {
                this.getMvpText.notes4 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        },
        notes5: {
            get() {
                return this.getMvpText.notes5;
            },
            set(value) {
                this.getMvpText.notes5 = value;
                this.$store.commit("setMvpText", this.getMvpText);
            }
        }
    }
};
</script>

<style>

</style>
