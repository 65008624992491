<template>
<div>
  <v-card color="black" class="mb-2 mb-8 px-8 py-2" tile dark>
    <v-row id="header" justify="space-between" align="center" no-gutters>
      <v-col sm="4">
        <v-btn @click="lastWeek" x-small icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-center" v-text="scheduleWeek < 19 ? 'Week ' + scheduleWeek : scheduleWeek == 19 ? 'Playoffs round 1' : scheduleWeek == 20 ? 'Playoffs round 2' : scheduleWeek == 21 ? 'Playoffs round 3' : scheduleWeek == 22 ? 'Playoffs round 4' : scheduleWeek == 23 ? 'Playoffs finals' : scheduleWeek" sm="4">  </v-col>
      <!-- <v-col class="text-center" v-text="'Week ' + scheduleWeek" sm="4">  </v-col> -->

      <v-col sm="4" class="text-right">
        <v-btn x-small @click="nextWeek" icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-card class="pa-8" min-height="100%">
    <v-img class="ma-0" :src="require('@/assets/Challenger1.png')" style="position: absolute; top:0; right: 0; left: 0;" />
    <v-row no-gutters>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-col sm="5">
        <filter-tree />
      </v-col>
    </v-row>
    <div>
      <v-divider v-if="$vuetify.breakpoint.smAndDown" class="my-4" />

      <v-row v-for="(i, k) in schedule[scheduleWeek - 1].games" :key="k" justify="space-between" align="center" :class="$vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'" no-gutters>
        <v-col sm="5">
          <v-card class="pa-6 mb-4 elevation-5" light>
            <v-row align="center" justify="space-around" :class="
                  $vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'
                " no-gutters>
              <v-col :sm="8">
                <v-row :class="
                      $vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'
                    " align="center" no-gutters>
                  <v-col sm="12" class="text-center" md="4">
                    <!-- TODO: replace with team avatar -->
                    <v-avatar class="white--text" color="red" size="56">
                      {{ i.homeTeam.teamName.substring(0, 3) }}
                    </v-avatar>
                  </v-col>
                  <v-col :class="$vuetify.breakpoint.mdAndUp ? '' : 'text-center'" sm="12" md="8">
                    <span class="body-1">
                      {{ i.homeTeam.teamName }}
                    </span>
                    
                  <!-- <span class="body-1" v-if="!isNaN(mappedStandings[i.homeTeam.teamName].totalWins)">
                  ({{ mappedStandings[i.homeTeam.teamName].totalWins }} -
                  {{ Number(mappedStandings[i.homeTeam.teamName].totalGames) - Number(mappedStandings[i.homeTeam.teamName].totalWins) }}
                  )</span>
                    <br /> -->
                    
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="12" md="4">
                <v-card flat class="pa-2 grey lighten-3 text-center">
                  <v-card-subtitle class="pa-0 ma-0">
                    Penetration Rate
                  </v-card-subtitle>

                  <span id="penRate-Schedule" class="text-h5">
                    {{ Number(i.homeTeam.teamAvgPenetration).toFixed(2) }}%
                  </span>
                  
               
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col sm="1">
          <v-row no-gutters justify="center">
            <v-card-title class="text-h4 mx-auto text-center pa-0 ma-0" :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : ''">
              VS
            </v-card-title>
          </v-row>
        </v-col>
        <v-col sm="5">
          <v-card class="pa-6 mb-4 elevation-5" light>
            <v-row align="center" justify="space-around" :class="
                  $vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'
                " no-gutters>
              <v-col :sm="8">
                <v-row :class="
                      $vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'
                    " align="center" no-gutters>
                  <v-col sm="12" class="text-center" md="4">
                    <!-- TODO: replace with team avatar -->
                    <v-avatar color="blue" class="white--text" size="56" style="text-transform: uppercase;">
                      {{ i.awayTeam.teamName.substring(0, 3) }}
                    </v-avatar>
                  </v-col>
                  <v-col :class="$vuetify.breakpoint.mdAndUp ? '' : 'text-center'" sm="12" md="8">
                    <span class="body-1">
                      {{ i.awayTeam.teamName }}
                    </span>
                     <!-- <span class="body-1" v-if="!isNaN(mappedStandings[i.awayTeam.teamName].totalWins)">
                  ({{ mappedStandings[i.awayTeam.teamName].totalWins }} -
                  {{ Number(mappedStandings[i.awayTeam.teamName].totalGames) - Number(mappedStandings[i.awayTeam.teamName].totalWins) }}
                  )</span>
                    <br /> -->
                    
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="12" md="4">
                <v-card flat class="pa-2 grey lighten-3 text-center">
                  <v-card-subtitle class="pa-0 ma-0">
                    Penetration Rate
                  </v-card-subtitle>

                  <span id="penRate-Schedule" class="text-h5">
                    {{ Number(i.awayTeam.teamAvgPenetration).toFixed(2) }}%
                  </span>
                  
                </v-card>
              </v-col>
            </v-row>
          </v-card>
          <v-divider v-if="$vuetify.breakpoint.smAndDown" class="mb-4" />
        </v-col>
      </v-row>
    </div>
  </v-card>
</div>
</template>

<script>
import {
  mapActions,
  mapState
} from "vuex";
import FilterTree from "@/components/app/FilterTree.vue";
export default {
  components: {
    FilterTree,
  },
  data() {
    return {
      title: "Schedule",
      headers: [{
          size: 6,
          name: "TEAM"
        },
        {
          size: 6,
          name: "RECORD"
        },
        {
          size: 2,
          name: "AVG PENETRATION"
        },
        {
          size: 1,
          name: "WIN"
        },
        {
          size: 2,
          name: "STREAK"
        },
        {
          size: 2,
          name: "TOTAL REVENUE"
        },
        {
          size: 1,
          name: "EDIT"
        },
      ],
      penRate: "1.42%",
      record: "6-3",
      sortBy: undefined,
      league: "American Football Conference",
      region: "AFC NORTH",
      teamName: "The Armodillos",
      teamCity: "Las Vegas",
      scheduleWeek:  23
    };
  },

  mounted() {
    this.getSchedule();
    this.getStandings();
    this.scheduleWeek = 23;
  },
  
  computed: {
    ...mapState({
      _schedule: "schedule",
      _mappedStandings: "mappedStandings",
    }),
    schedule() {
      return this._schedule || [];
    },
    mappedStandings() {
      return this._mappedStandings
    }
  },
  methods: {
    ...mapActions({
      getSchedule: "getSchedule",
      getStandings: "getStandings",
    }),
    currentWeek() {
      const d1 = 'May 16 2022';
      const d2 = new Date();
      
      function datediff(first, second) {
        return Math.round((Date.parse(second) - Date.parse(first)) / (1000 * 60 * 60 * 24));
      }
      const days = datediff(d1,d2);
      const weekNo = Math.ceil(days / 7);
      // console.log('weekNo: ', weekNo)
      if (weekNo === 1) {
        return 2;
      } else {
        return weekNo + 1;
      }
    },
    nextWeek() {
      // console.log('week: ', this.scheduleWeek)
      if(this.scheduleWeek > 22) {
        this.scheduleWeek = 1;
      }
      else if (this.scheduleWeek < 26) {
        this.scheduleWeek++;
      } else {
        this.scheduleWeek = 1;
      }
    },
    lastWeek() {
      // console.log('week: ', this.scheduleWeek)
      if (this.scheduleWeek > 1) {
        this.scheduleWeek--;
      } else {
        this.scheduleWeek = this.schedule.length;
      }
    },
  },
};
</script>
