<template>
  <div>
    <v-card color="black" class="mb-2 mb-8 pa-8 py-2" tile dark>
      <v-row id="header" justify="space-between" align="center" no-gutters>
        <v-col class="text-center" sm="12"> {{ title }}</v-col>
      </v-row>
    </v-card>
    <v-card id="dashboard" height="100%">
      <v-img
        class="ma-0"
        :height="$vuetify.breakpoint.mdAndUp ? 'calc(80vh - 80px)' : '100%'"
        :src="require('@/assets/dashbg.png')"
      >
        <v-card
          class="mx-auto"
          :class="$vuetify.breakpoint.mdAndUp ? 'my-16' : 'my-4'"
          :width="$vuetify.breakpoint.mdAndUp ? '75%' : '90%'"
        >
          <v-row
            no-gutters
            justify="start"
            align="center"
            :style="$vuetify.breakpoint.mdAndUp ? 'height: 40%' : ''"
          >
            <v-col
              cols="12"
              lg="4"
              xl="2"
              md="4"
              sm="12"
              class="pa-8 text-center"
              justify="center"
              align="center"
            >
              <div style="width: 100%;">
                <v-avatar
                  color="red"
                  size="100"
                  style="margin: 0 auto;"
                  class=""
                  ><v-card-title
                    class="white--text"
                    style="text-transform: uppercase;"
                    >{{
                      (teams && teams.teamLocation) ? teams.teamLocation.substring(0, 3) : ""
                    }}</v-card-title
                  ></v-avatar
                >
              </div>
            </v-col>
            <v-col
              cols="12"
              :class="$vuetify.breakpoint.mdAndUp ? '' : 'pa-4'"
              sm="12"
              md="8"
              lg="8"
              xl="10"
            >
              <span
                class="px-2"
                style="
                  font-size: 32px;
                  font-weigth: 900;
                  text-transform: uppercase;
                "
              >
                {{ teams ? teams.teamName : "" }}
              </span>
              <br />
              <span
                style="
                  font-size: 21px;
                  font-weigth: 900;
                  text-transform: uppercase;
                "
                class="py-0 px-2 mb-0"
              >
                {{ teams ? teams.teamLocation : "" }}
              </span>
              <br />
              <span
                class="pt-0 px-2 mt-0"
                style="
                  color: #777777;
                  font-size: 12px;
                  font-weigth: 900;
                  text-transform: uppercase;
                "
              >
                {{ teams ? teams.divisionName : "" }}
              </span>
            </v-col>
          </v-row>
          <v-row
            :class="$vuetify.breakpoint.mdAndUp ? 'flex-row' : ''"
            no-gutters
          >
            <v-card
              :width="
                $vuetify.breakpoint.mdAndUp
                  ? 'calc(25% - 32px)'
                  : 'calc(50% - 32px)'
              "
              class="ma-4"
              ><v-card-subtitle class="pb-1"> RECORD </v-card-subtitle>
              <v-card-subtitle class="my-0 py-2 text-h4">
                {{ teams && Number(teams.totalWins) }} -
                    {{ teams && (Number(teams.totalGames) - Number(teams.totalWins)) }} 
                      </v-card-subtitle
              ></v-card
            >

            <v-card
              :width="
                $vuetify.breakpoint.mdAndUp
                  ? 'calc(50% - 32px)'
                  : 'calc(50% - 32px)'
              "
              class="ma-4"
              ><v-card-subtitle class="pb-1"> STREAK </v-card-subtitle>
              <v-card-subtitle class="my-0 py-2 text-h4"
                >
               
                <span id="streak" v-if="teams && teams.winStreak">
                  {{ teams.winStreak }}
                  {{ teams.winStreak > 1 ? "games" : "game &nbsp;" }} - [W]
                </span>
                <span id="streak" v-else-if="teams && teams.loseStreak">
                  {{ teams.loseStreak }}
                  {{ teams.loseStreak > 1 ? "games" : "game &nbsp;" }} - [L]
                </span>
                <span id="streak" v-else>
                  -
                </span></v-card-subtitle
              ></v-card
            >
            <v-card
              :width="
                $vuetify.breakpoint.mdAndUp
                  ? 'calc(25% - 32px)'
                  : 'calc(100% - 32px)'
              "
              class="ma-4"
              ><v-card-subtitle class="pb-1"> WIN PERCENTAGE </v-card-subtitle>
              <v-card-subtitle class="my-0 py-2 text-h4"
                >{{
                  teams && teams.winPercentage
                    ? (Number(teams.winPercentage)*100).toFixed(0)
                    : "0"
                }}%</v-card-subtitle
              >
            </v-card>
          </v-row>
          <v-row no-gutters>
            <v-card
              :width="
                $vuetify.breakpoint.mdAndUp
                  ? 'calc(50% - 32px)'
                  : 'calc(100% - 32px)'
              "
              class="ma-4"
              ><v-card-subtitle class="pb-1">
                TOTAL PENETRATION RATE
              </v-card-subtitle>
              <v-card-subtitle class="my-0 py-2 text-h4"
                >{{
                  teams && teams.avgPenetration
                    ? Number(teams.avgPenetration).toFixed(2)
                    : "0"
                }}%
              </v-card-subtitle></v-card
            >
            <v-card
              :width="
                $vuetify.breakpoint.mdAndUp
                  ? 'calc(50% - 32px)'
                  : 'calc(100% - 32px)'
              "
              class="ma-4"
              ><v-card-subtitle class="pb-1"> TOTAL REVENUE </v-card-subtitle>
              <v-card-subtitle class="my-0 py-2 text-h4">
                <money-format
                  :value="teams && teams.totalRevenue ? teams.totalRevenue : 0"
                  locale="en"
                  currency-code="USD"
                  :subunits-value="false"
                  :hide-subunits="false"
                >
                </money-format></v-card-subtitle
            ></v-card>
          </v-row>
         
        </v-card>
        <!-- </v-card>
    </v-row> -->
      </v-img>
      
    </v-card>
  </div>
</template>
<script>
import MoneyFormat from "vue-money-format";

import { mapActions, mapState } from "vuex";
export default {
  components: {
    MoneyFormat,
  },
  data() {
    return {
      team: undefined,
      title: "Dashboard",
      teamArea: "CITY",
      teamRegion: "REGION",
      teamName: "TEAM NAME",
    };
  },
  async mounted() {
    await this._getCurrentSeason();
    await this._getLeagueTeams();
    this._getTeam();
    this._getSchedule();
  },

  computed: {
    ...mapState({
      _team: "team",
      _user: "auth/currentUser",
    }),
    teams() {
      return this._team;
    },
  },
  methods: {
    ...mapActions({
      _getTeam: "getDashboard",
      _getSchedule: "getSchedule",
      _getCurrentSeason: "getCurrentSeason",
      _getLeagueTeams: "getLeagueTeams",
    }),
  },
};
</script>
