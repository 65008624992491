/* eslint-disable no-unused-vars */
import { auth } from "../../api";
import router from "../../router";

// initial users state
const state = {
  loggedIn: false,
  currentUser: undefined,
  error: null,
  success: false,
};

// getters
const getters = {
  getCurrentUser: (state) => {
    return state.currentUser;
  },
  getIsLoggedIn: (state) => {
    return state.loggedIn;
  },
  getError: (state) => {
    return state.error;
  },
  getSuccess: (state) => {
    return state.success;
  },
};

const actions = {
  logOut: async ({ commit, dispatch }) => {
    // dispatch("localStore/removeToken", true, { root: true });
    commit("setLogStatus", false);
    localStorage.removeItem("auth");
    router.push("/login");
  },
  logIn: async ({ commit, dispatch }, payload) => {
    commit("setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      auth(
        "/user?query=" +
          encodeURIComponent(
            `{auth(type_name: "user", 
            user: {email: "${payload.email}", password: "${payload.password}"}, 
          ){
            uuid,
            user_name, 
            email,
            location,
            type_name,
            client_id,
            address{
              address_1,
              address_2,
              city,
              country
            },
            contact{
              contact_name,
              phone,
              email
            },
            password,
            success, 
            error
          }}`
          )
      )
        .then((res) => {
          if (res.data.auth.email) {
            commit("setLogStatus", true);
            commit("setCurrentUser", res.data.auth);
            commit("changePage", "Dashboard", { root: true });
            if (window.location.pathname === "/login") {
              router.push("/");
            }

            commit("setLoading", false, { root: true });
            resolve(res);
          } else {
            if (res.data?.auth?.error === "Not authenticated") {
              commit("setError", "Invalid email or password");
              reject("invalid email or password");
            } else if (res.data?.auth?.error === "User not verified") {
              commit("setError", "Please verify your email address");
              reject("unverified user");
            }
            commit("setLogStatus", true);
            commit("setLoading", false, { root: true });
          }
        })
        .catch((error) => {
          commit("setLoading", false, { root: true });
          reject(error);
        });
    });
  },
  checkToken: async ({ dispatch, state }) => {
    // console.log(localStorage.getItem("auth"))
    if (!state.loggedIn && localStorage.getItem("auth")) {
      try {
        let auth = atob(localStorage.getItem("auth"));
        let user = {
          email: JSON.parse(auth).email,
          password: atob(JSON.parse(auth).password),
        };

        dispatch("logIn", user);
      } catch (err) {
        console.error(err);
        router.push("/");
      }
    } else if (state.loggedIn && router.history.current.name === "Login") {
      router.push("/");
    }
  },
  forgotPassword: async ({ dispatch, commit }, payload) => {
    return new Promise((resolve, reject) => {
      auth(
        "/user?query=" +
          encodeURIComponent(
            `{request_reset(user:{email: "${payload}"}){success, error}}`
          )
      )
        .then((res) => {
          if (!res?.data?.request_reset?.error) {
            console.log("succ")
            commit("setSuccess", true);
            commit("setError", null)
          }
          else {
            console.log("err")
            if (res?.data?.request_reset?.error === 'email not found')
              commit("setError", "Email address not found")
            else 
              commit("setError", "Something went wrong. Please try again later")
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  register: async ({ dispatch, commit }, payload) => {
    // console.log("payload", payload.team)
    commit("setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      if (!payload.first_name || !payload.email || !payload.password || !payload.team) {
        commit("setError", "Please input all the fields");
        commit("setSuccess", false);
        reject("fields not filled");
      } else {

        let name = payload.first_name + (payload.last_name || "");
        auth(
          "/user?query=" +
            encodeURIComponent(
              `{signup( user: {type_name: "user", email: "${payload.email}", password: "${payload.password}", user_name: "${name}", location: "${payload.team}"} ){uuid,user_name,email,location,type_name,client_id,address {address_1,address_2,city,country},contact{contact_name,phone,email },password,success,error}}`
            )
        )
          .then((res) => {
            // console.log("Res: ", res);
            if (!res?.data?.signup?.error) {
              commit(
                "setSuccess",
                "Thanks for signing up. Please verify your email address to proceed"
              );
              commit("setError", null);
              resolve(res);
            } else {
              if (res?.data?.signup?.error === "This email already exists!") {
                commit("setError", "This email already exists!");
                commit("setSuccess", false);
                reject("email already exists");
              } else {
                commit(
                  "setError",
                  "Something went wrong. Please try again after some time"
                );
                commit("setSuccess", false);
                reject("something went wrong");
              }
              commit("setLogStatus", true);
              commit("setLoading", false, { root: true });
            }
          })
          .catch((error) => {
            commit("setLoading", false, { root: true });
            reject(error);
          });
      }

    });
  },
  verify: async ({ dispatch, commit }, payload) => {
    commit("setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      let uuid = payload.uuid;
      auth(
        "/user?query=" +
          encodeURIComponent(
            `{ verify ( type_name: "user", uuid: "${uuid}") {success, error} }`
          )
      )
        .then((res) => {
          if (res.data?.verify?.error) {
            if (res.data?.verify?.error === "uuid not found")
              commit("setError", "Something went wrong");
            else commit("setError", "Something went wrong");
            commit("setSuccess", false);
          } else {
            commit("setError", null);
            commit("setSuccess", "Your email has been verified!");
          }
        })
        .catch((error) => {
          commit("setLoading", false, { root: true });
          reject(error);
        });
    });
  },
  reset_password: async ({ dispatch, commit }, payload) => {
    if(payload.password !== payload.password_confirm) {
      commit("setError", "Passwords don't match");
      commit("setSuccess", false);
      return;
    }

    commit("setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      auth(
        "/user?query=" +
          encodeURIComponent(
            `{reset_password(user:{email: "${payload.email}" password_code: "${payload.password_code}", password: "${payload.password}"}){success, error}}`
          )
      )
        .then((res) => {
          if (res.data?.reset_password?.error) {
            if (res.data?.reset_password?.error === "email not found")
              commit("setError", "Email address not found");
            else commit("setError", "Something went wrong");
            commit("setSuccess", false);
          } else {
            commit("setError", null);
            commit("setSuccess", "Your password has been reset");
          }
        })
        .catch((error) => {
          commit("setLoading", false, { root: true });
          reject(error);
        });
    });
  },
};

const mutations = {
  setLogStatus(state, payload) {
    state.loggedIn = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setCurrentUser(state, payload) {
    // console.log(payload, 'pay')
    localStorage.setItem("auth", btoa(JSON.stringify(payload)));
    state.currentUser = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
