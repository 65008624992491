<template>
  <v-card
    color="black"
    v-if="$vuetify.breakpoint.mdAndUp"
    class="my-2 mx-4 mb-4 px-8 py-2"
    tile
    dark
  >
    <v-row id="header" align="center" no-gutters>
      <v-col v-for="(i, k) in headers" :key="k" :sm="i.size">
        <span
          class="text-caption"
          style="font-size: 14px !important;"
          color="white--text"
        >
          {{ i.name }}&nbsp;
        </span>
        <v-icon
          v-if="i.sortBy"
          small
          @click="setSort(i)"
          :class="_sortBy === i.sortBy ? 'orange--text' : ' '"
          >mdi-chevron-up</v-icon
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      headers: [
        { size: 3, name: "TEAM", sortBy: "teamName" },
        { size: 1, name: "RECORD", sortBy: "totalWins" },
        { size: 2, name: "AVG PENETRATION", sortBy: "avgPenetration" },
        { size: 2, name: "WIN %", sortBy: "winPercentage" },
        { size: 2, name: "STREAK" },
        { size: 2, name: "TOTAL REVENUE", sortBy: "totalRevenue" },
        // { size: 1, name: "EDIT" },
      ],
      sortBy: "",
    };
  },
  computed: {
    ...mapState({
      _sortBy: "sortBy",
    }),
  },
  methods: {
    ...mapMutations({
      _setSort: "setSort",
    }),
    setSort(e) {
      this._setSort(e.sortBy);
    },
  },
};
</script>
