<template>
  <div style="width: 100%;">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-4" no-gutters>
      <v-col sm="4" class="pa-1">
        <v-autocomplete
          :items="_conferences"
          item-text="label"
          item-value="id"
          v-model="conference"
          label="Conference"
          @change="setConference(filter)"
          solo
          clearable
          hide-details
        />
      </v-col>
      <v-col sm="4" class="pa-1">
        <v-autocomplete
          :items="_divisions"
          item-text="label"
          item-value="id"
          label="Division"
          v-model="division"
          @change="setDivision(filter)"
          solo
          clearable
          hide-details
        />
      </v-col>
      <v-col sm="4" class="pa-1">
        <v-autocomplete
          :items="_teams"
          item-text="label"
          item-value="id"
          label="Team"
          v-model="team"
          @change="setTeam(filter)"
          solo
          clearable
          hide-details
        />
      </v-col>
    </v-row>
    <v-row v-else justify="end" no-gutters>
      <v-spacer />
      <v-btn color="red" @click="dialog = !dialog" class="mt-4 white--text">
        FILTER BY &nbsp;
        <v-icon small>mdi-filter</v-icon>
      </v-btn>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          FILTER BY
        </v-card-title>

        <v-card-text class="pa-2">
          <v-autocomplete
            :items="_conferences"
            item-text="label"
            item-value="id"
            v-model="conference"
            label="Conference"
            @change="setConference(filter)"
            filled
            clearable
            hide-details
            class="ma-1"
          />
          <v-autocomplete
            :items="_divisions"
            item-text="label"
            item-value="id"
            label="Division"
            v-model="division"
            @change="setDivision(filter)"
            filled
            clearable
            hide-details
            class="ma-1"
          />
          <v-autocomplete
            :items="_teams"
            item-text="label"
            item-value="id"
            label="Team"
            v-model="team"
            @change="setTeam(filter)"
            filled
            clearable
            hide-details
            class="ma-1"
          />
          <v-autocomplete
            v-if="_page === 'Standings'"
            :items="sortables"
            item-text="name"
            item-value="value"
            label="Sort By..."
            v-model="sortBy"
            @change="setSort(filter)"
            filled
            clearable
            hide-details
            class="ma-1"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
// import TreeSelect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    // TreeSelect,
  },
  data() {
    return {
      filterVal: undefined,
      conference: "",
      division: "",
      dialog: false,
      sortables: [
        { name: "Record", value: "totalWins" },
        { name: "AVG Penetration", value: "avgPenetration" },
        {
          name: "Win %",
          value: "winPercentage",
        },
        { name: "Total Revenue", value: "totalRevenue" },
      ],
      sortBy: "totalWins",
      team: "",
    };
  },
  mounted() {
    // this.getStandings();
  },
  methods: {
    ...mapActions({
      getStandings: "getStandings",
      getSchedule: "getSchedule",
    }),
    ...mapMutations({
      // _setFilter: "setFilter",
      _setDivision: "setDivision",
      _setConference: "setConference",
      _setSort: "setSort",
      _setTeam: "setTeamName",
    }),
    setDivision(e) {
      this._setDivision(e.division);
    },
    setConference(e) {
      this._setConference(e.conference);
    },
    setTeam(e) {
      this._setTeam(e.team);
    },
    setSort(e) {
      this._setSort(e.sortBy);
    },
    // updateFilter(e) {
    //   this._setFilter(e);
    // },
    getData() {
      if (this._page === "Standings") {       
        this.getStandings();
      } else {
        this.getSchedule();
      }
    },
  },
  beforeDestroy() {
    this._setTeam("");
    this._setSort("totalWins");
    this._setConference("");
    this._setDivision("");
  },
  computed: {
    ...mapState({
      // _standings: "standings",
      _filter: "filter",
      _conferences: "conferenceList",
      _divisions: "divisionList",
      _divisionName: "divisionName",
      _conferenceName: "conferenceName",
      _page: "page",
      _team: "teamName",
      _sortBy: "sortBy",
      _teams: "teamList",
    }),
    filter() {
      return {
        conference: this.conference,
        division: this.division,
        team: this.team,
        sortBy: this.sortBy,
      };
    },
  },
  watch: {
    _team() {
      this.getData();
    },
    _sortBy() {
      this.getData();
    },
    _divisionName() {
      this.getData();
    },
    _conferenceName() {
      this.getData();
    },
  },
};
</script>
