<template>
  <v-bottom-navigation fixed hide-on-scroll>
    <v-btn small @click="navigate(i)" v-for="(i, k) in navi" :key="k">
      <span>{{ i.name }}</span>
      <v-icon>{{ i.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      navi: [
        { name: "Dashboard", icon: "mdi-view-dashboard" },
        // { name: "Standings", icon: "mdi-star-circle" },
        { name: "Schedule", icon: "mdi-calendar" },
        { name: "SmackTalk", icon: "mdi-chat" },
        { name: "HypeVideo", icon: "mdi-video" },
      ],
    };
  },
  methods: {
    ...mapMutations({
      _changePage: "changePage",
    }),
    navigate(i) {
      this._changePage(i.name);
    },
  },
};
</script>
