<template functional>
  <v-avatar class="ma-2 white--text" :color="props.color">
    <!-- <v-icon>mdi-account</v-icon> -->
    {{ props.threeLetters }}
  </v-avatar>
</template>
<script>
export default {
  functional: true,
  props: {
    color: String,
    threeLetters: String,
  },
};
</script>
