<template>
  <v-card
    class="ma-6 pa-8"
    :width="
      $vuetify.breakpoint.xlAndUp ? 'calc(30vw - 80px)' : 'calc(20vw - 80px)'
    "
    min-height="80vh"
    tile
  >
    <v-img width="85%" class="mx-auto my-8" src="@/assets/Challenger.png" />
    <v-divider />
    <v-list-item @click="navigate(i)" v-for="(i, k) in navi" :key="k">
      <v-list-item-title>{{ i }}</v-list-item-title>
    </v-list-item>
  </v-card>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      navi: ["Dashboard", "Standings",  "Schedule", "SmackTalk", "HypeVideo"],
    };
  },
  methods: {
    ...mapMutations({
      _changePage: "changePage",
    }),
    navigate(i) {
      this._changePage(i);
    },
  },
};
</script>
